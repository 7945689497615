import React, { useEffect } from "react";
import { createContext, useState } from "react";

export const Context = createContext();

const Provider = ({ children }) => {
    const [selectedLanguage, setSelectedLanguage] = useState("en");
    const [texts, setTexts] = useState([]);

    const changeLanguage = (lang) => {
        setSelectedLanguage(lang);
    };

    useEffect(() => {
        const fetchLang = async () => {
            const response = await fetch(`../${selectedLanguage}.json`);
            const newData = await response.json();
            setTexts(newData);
        };

        fetchLang();
    }, [selectedLanguage]);

    const getText = (key) => {
        return texts[key];
    };

    return (
        <Context.Provider value={{ selectedLanguage, changeLanguage, getText }}>
            {children}
        </Context.Provider>
    );
};

export default Provider;