import React from "react";
import Modal from "react-bootstrap/Modal";

import "./modal.css";

function ModalContainer(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton></Modal.Header>

            <Modal.Body>{props.children}</Modal.Body>
        </Modal>
    );
}

export default ModalContainer;