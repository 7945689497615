import React from "react";
import { FabMedia } from "../FabMedia/FabMedia";
import { Context } from "../../context/Context";
import { useContext } from "react";
import "./footer.css";

const Footer = () => {
    const { getText } = useContext(Context);

    return (
        <>
            <section
                id="contact"
                className="d-flex justify-content-center align-content-center pt-5"
            >
                <div className="col-12 d-flex justify-content-center align-items-center flex-column">
                    <h2 className="titlesH2 pb-4">
                        {getText("title.secondary2")}
                    </h2>
                    <h3 className="titlesH3 pb-2">{getText("text.cta2")}</h3>
                    <div className="textMail pb-4">
                        <a
                            href="mailTo:supergisedesign@gmail.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="textMail"
                        >
                            supergisedesign@gmail.com
                        </a>
                    </div>
                    <p className="regularText">{getText("info.footer")}</p>
                    <img
                        src="./assets/logo.svg"
                        className="d-inline-block align-center logo"
                        alt="Súper Gise logo"
                    />
                    <div></div>
                </div>
            </section>
            <FabMedia />
        </>
    );
};

export { Footer };