import Carousel from 'react-bootstrap/Carousel';
import './slide.css';

function Slide({ items, size }) {

    return (
            <Carousel interval = { null } className = { size } > 
                { items.map ((item) => (
                        <Carousel.Item key = { item.title }>
                            <img
                                className = "d-block w-100"
                                src = { item.img }
                                alt = { item.alt }
                            />
                            <Carousel.Caption>
                                <h3 className = 'regularText text-black'>{ item.title }</h3>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))
                }
            </Carousel>
    );
}

export { Slide };