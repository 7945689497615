import React from 'react';
import { Parallax } from '../Parallax/Parallax';
import { About } from '../About/About';
import { Works } from '../Works/Works';
import './main.css';

const Main = () => {
    return (
        <>
            <Parallax />
            <About /> 
            <Works />
        </>
    );
};

export { Main };   