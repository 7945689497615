import React from "react";
import ModalContainer from "../ModalContainer/ModalContainer";
import { Slide } from "../Slide/Slide";
import { useContext } from 'react';
import { Context } from "../../context/Context";
import "./laMadriguera.css";

const LaMadriguera = () => {
    const { getText } = useContext(Context);
    
    const slide1 = [
        {
            img: "assets/img/fotoprueba.jpg",
            alt: "una mano abriendo un sujeta cables",
            title: "",
        },
        {
            img: "assets/img/idea.jpg",
            alt: "bola de cables enredados",
            title: "Idea rectora",
        },
        {
            img: "assets/img/earphone.jpg",
            alt: "sujeta auriculares en tres colores",
            title: "Earphone case",
        },
        {
            img: "assets/img/roll.jpg",
            alt: "organizador de cables en tubo",
            title: "Roll Case",
        },
        {
            img: "assets/img/rollvarios.jpg",
            alt: "organizadores de cables",
            title: "Roll case",
        },
        {
            img: "assets/img/mousepad.jpg",
            alt: "mouse pad en escritorio",
            title: "Mouse Pad",
        },
        {
            img: "assets/img/imacfoot.jpg",
            alt: "base para pie de imac",
            title: "Imac Foot",
        },
        {
            img: "assets/img/ipadcase.jpg",
            alt: "estuche para ipad",
            title: "Ipad Case",
        },
        {
            img: "assets/img/pitstopcase.jpg",
            alt: "estuche para colgar el celular",
            title: "PitStop Case",
        },
        {
            img: "assets/img/handmadenotebook.jpg",
            alt: "bitácora artesanal",
            title: "Handmade Notebook",
        },
    ];

    const slide2 = [
        {
            img: "assets/img/identidad.jpg",
            alt: "lapiz con texto",
            title: "",
        },
        {
            img: "assets/img/tarjetas.jpg",
            alt: "tarjetas, tags, lápiz",
            title: "Tarjetas y tags",
        },
        {
            img: "assets/img/tarjetasfiestas.jpg",
            alt: "tarjeta de fiesta",
            title: "Ilustraciones",
        },
        { img: "assets/img/logo.jpg", alt: "logo de marmota", title: "Logo" },
        {
            img: "assets/img/cartel.jpg",
            alt: "cartel luminoso con marmota",
            title: "Cartelería",
        },
    ];

    const slide3 = [
        {
            img: "assets/img/contentcreator.jpg",
            alt: "bola de cables usb enreedados",
            title: "",
        },
        {
            img: "assets/img/catalogo1.jpg",
            alt: "catalogo de organizador de cables",
            title: "Catalogos",
        },
        {
            img: "assets/img/newsletter.jpg",
            alt: "newsletter",
            title: "Newsletter",
        },
        {
            img: "assets/img/redes1.jpg",
            alt: "ejemplo de frase",
            title: "Contenido",
        },
        {
            img: "assets/img/redes2.jpg",
            alt: "ejemplo de frase",
            title: "Frases",
        },
        {
            img: "assets/img/redes3.jpg",
            alt: "ejemplo de frase",
            title: "Frase",
        },
        {
            img: "assets/img/banner2.jpg",
            alt: "ejemplo de banners",
            title: "Banners redes",
        },
    ];

    const [modalShow1, setModalShow1] = React.useState(false);
    const [modalShow2, setModalShow2] = React.useState(false);
    const [modalShow3, setModalShow3] = React.useState(false);

    return (
        <div
            id="laMadriguera"
            className="d-flex justify-content-center alig-items-center flex-column"
        >
            <section>
                <div className="container-fluid d-flex flex-column">
                    <div className="row">
                        <h2 className="titlesH2 d-flex justify-content-center align-items-center pb-1">
                            LA MADRIGUERA GIFTS
                        </h2>
                    </div>

                    <div className="row">
                        <h3 className="titlesH3 d-flex justify-content-center align-items-center pb-5">
                            {getText("title.work")}
                        </h3>
                    </div>

                    <div className="d-flex justify-content-center flex-column ps-0 pe-0 ps-md-5 pe-md-5">
                        <p className="regularText text-left ps-0 pe-0 ps-md-5 pe-md-5">
                            {getText("text.work")}
                        </p>
                        <p className="regularText text-left ps-0 pe-0 ps-md-5 pe-md-5">
                            {getText("text.work2")}
                        </p>
                        <p className="regularText text-left ps-0 pe-0 ps-md-5 pe-md-5 pb-5">
                            {getText("text.work3")}
                        </p>
                    </div>

                    <div className="row justify-content-center carousel-img">
                        <img
                            src="./assets/img/fotoprueba.jpg"
                            alt="manos sosteniendo un atrapa cables"
                            className="w-25 image"
                            onClick={() => setModalShow1(true)}
                        />

                        <img
                            src="./assets/img/identidad.jpg"
                            alt="manos sosteniendo un lápiz"
                            className="w-25 image"
                            onClick={() => setModalShow2(true)}
                        />

                        <img
                            src="./assets/img/contentcreator.jpg"
                            alt="manos sosteniendo una bola de cables usb"
                            className="w-25 image"
                            onClick={() => setModalShow3(true)}
                        />
                    </div>
                </div>
            </section>

            <ModalContainer
                show={modalShow1}
                onHide={() => setModalShow1(false)}
            >
                <Slide items={slide1} />
            </ModalContainer>

            <ModalContainer
                show={modalShow2}
                onHide={() => setModalShow2(false)}
            >
                <Slide items={slide2} />
            </ModalContainer>

            <ModalContainer
                show={modalShow3}
                onHide={() => setModalShow3(false)}
            >
                <Slide items={slide3} />
            </ModalContainer>
        </div>
    );
};

export { LaMadriguera };