import React from "react";
import "./parallax.css";

const Parallax = () => {
    let images;

    const range = 8;
    const calcValue = (a, b) => ((a / b) * range - range / 2).toFixed(1);
    // const bgSpace = "./assets/img/backg4.png";
    const bgSpace1 = "./assets/img/backg2.png";
    const bgSpace2 = "./assets/img/backg3.png";

    let timeout;
    document.addEventListener(
        "mousemove",
        ({ x, y }) => {
            if (timeout) {
                window.cancelAnimationFrame(timeout);
            }

            timeout = window.requestAnimationFrame(() => {
                const yValue = calcValue(y, window.innerHeight);
                const xValue = calcValue(x, window.innerWidth);

                if (images == null) {
                    images = document.querySelectorAll(".card__img");
                }

                var i = -2;
                images.forEach((image) => {
                    image.style.transform = `translateX(${
                        i * xValue
                    }px) translateY(${-i * yValue}px)`;
                    i++;
                    if (i === 0) i++;
                });
            });
        },
        false
    );

    return (
        <>
            <div className="cards">
                <div className="card noborder card__one">
                    <img
                        className="card__img d-none d-md-block"
                        src={bgSpace1}
                        alt="dibujos del espacio"
                    />
                    {/* <img
                        className="card__img position-absolute d-none d-md-block"
                        src={bgSpace}
                        alt="dibujos del espacio"
                    /> */}
                    <img
                        className="card__img position-absolute d-none d-md-block"
                        src={bgSpace2}
                        alt="dibujos del espacio"
                    />
                </div>
            </div>
        </>
    );
};

export { Parallax };