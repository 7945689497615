import React from "react";
import "./fabMedia.css";

const FabMedia = () => {
    return (
        <>
            <section id="fab" className="container-fluid">
                <div className="row fixedPositionLf">
                    <div className="d-flex flex-sm-column">
                        <a
                            href="https://www.linkedin.com/in/giselle-cabal-lascano/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="./assets/icons/iconlinkedin.svg"
                                alt="botón linkedin"
                                className="iconRedes pb-3"
                            />
                        </a>

                        <a
                            href="https://www.upwork.com/freelancers/~01c53bfdc967dea978"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="./assets/icons/iconupwork.svg"
                                alt="botón upwork red social"
                                className="iconRedes pb-3"
                            />
                        </a>

                        <a
                            href="https://github.com/supergise"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="./assets/icons/icongithub.svg"
                                alt="botón github"
                                className="iconRedes pb-3"
                            />
                        </a>


                        <a
                            href="https://www.behance.net/supergise"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="./assets/icons/iconbehance.svg"
                                alt="botón behance red social"
                                className="iconRedes"
                            />
                        </a>

                    </div>
                </div>
            </section>

            <section className="fixedPositionRg d-none d-sm-block">
                <div className="textMail textMailRotate">
                    <a
                        href="mailTo:supergisedesign@gmail.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="textMail ps-3"
                    >
                        supergisedesign@gmail.com
                    </a>
                </div>
            </section>
        </>
    );
};

export { FabMedia };