import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./fonts/JetBrainsMono-Regular.ttf";
import ReactGA from 'react-ga4';

ReactGA.initialize('G-38CK4EK000'); 

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <>
        <Router>
            <App />
        </Router>
    </>
    
);
