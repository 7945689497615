import React from "react";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";
import { useContext } from 'react';
import "./works.css";

const Works = () => {
    const { getText } = useContext(Context);

    return (
        <>
            <section
                id="works"
                className="works container-fluid d-flex flex-column justify-content-center align-content-center"
            >
                <div className="row">
                    <h2 className="titlesH2 d-flex justify-content-center align-items-center pb-5">
                    { getText("title.secondary") }
                    </h2>
                </div>

                <div className="d-flex justify-content-center flex-column text-decoration-none">
                    <h3 className="pb-3 text-left">
                        <a
                            href="https://sporehills.vercel.app/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="titlesWorks"
                        >
                            { getText("work.link") }
                        </a>

                        <a
                            href="https://github.com/supergise/Palacios"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="./assets/icons/icongithub.svg"
                                alt="botón github"
                                className="iconRedes ps-3 pe-3"
                            />
                        </a>
                    </h3>

                    <h3 className="pb-3 text-left">
                        <a
                            href="https://idearse.netlify.app/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="titlesWorks"
                        >
                            { getText("work.link2") }
                        </a>
                    </h3>

                    <h3 className="pb-3 text-left">
                        <Link to="/lamadriguera" className="titlesWorks">
                        { getText("work.link3") }
                        </Link>
                    </h3>

                    <h3 className="pb-3 text-left">
                        <a
                            href="https://www.behance.net/gallery/164489329/Case-Study-Lolo-App-de-Salud-para-adultos-mayores"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="titlesWorks"
                        >
                            { getText("case.study") }
                            <img
                                src="./assets/icons/iconbehance.svg"
                                alt="botón github"
                                className="iconRedes ps-3 pe-3"
                            />
                        </a>
                    </h3>

                    <h3 className="pb-3 text-left">
                        <a
                            href="https://supergise.github.io/Bharani-deco/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="titlesWorks"
                        >
                            { getText("proyect") }
                        </a>
                    </h3>

                    <h3 className="d-inline-flex text-left align-items-center">
                        <a
                            href="https://37080-depot-react-cabal-lascano-8cov.vercel.app/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="titlesWorks"
                        >
                            { getText("proyect2") }
                        </a>

                        <a
                            href="https://github.com/supergise/Depot-ecommerce"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="./assets/icons/icongithub.svg"
                                alt="botón github"
                                className="iconRedes ps-3 pe-3"
                            />
                        </a>
                    </h3>
                </div>
            </section>
        </>
    );
};

export { Works };