import React from "react";
import { useContext } from 'react';
import { Context } from "../../context/Context";
import "bootstrap/dist/css/bootstrap.min.css";

const LanguageSwitcher = ( ) => {
    const { selectedLanguage, changeLanguage } = useContext(Context);

    const handleLanguageChange = () => {
        changeLanguage(selectedLanguage === "en" ? "es" : "en");
    };

    return (
        <div>
            <div className="form-check form-switch">
                <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckLanguage"
                    checked={selectedLanguage === "es"}
                    onChange={handleLanguageChange}
                />
                 <label
                    className="form-check-label"
                    htmlFor="flexSwitchCheckLanguage"
                    style={ { right : selectedLanguage === "en" ? "64px" : "88px" } }
                 >
                    {selectedLanguage === "en" ? "EN" : "ES"}
                </label> 
            </div>
        </div>
    );
};

export default LanguageSwitcher;
