import React, { useState } from "react";
import { Context } from "../../context/Context";
import { useContext } from "react";
import "./readMore.css";

const ReadMore = ({ children }) => {
    const { getText } = useContext(Context);

    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    return (
        <p>
            {isReadMore ? "" : text}
            <span onClick={toggleReadMore} className="read-or-hide">
                {isReadMore ? getText("read.more") : getText("read.less")}
            </span>
        </p>
    );
};

export { ReadMore };