import React from "react";
import { ReadMore } from "../ReadMore/ReadMore";
import { Context } from "../../context/Context";
import { useContext } from "react";

import "./about.css";

const About = () => {
    const { getText } = useContext(Context);

    return (
        <section
            id="about"
            className="container-fluid d-flex align-items-center justify-content-center"
        >
            <div>
                <div className="row">
                    <div className="col-12 col-lg-6 mainTitle d-flex flex-column align-items-center justify-content-center">
                        <img
                            src="./assets/img/me.png"
                            className="d-inline-block align-center me-photo"
                            alt="Foto de Gise"
                        />
                        {/* <h2>&#60;Hello</h2>
                        <h2>Word&#62;</h2> */}
                    </div>

                    <div className="col-12 col-lg-6 titlesH3 d-flex flex-column align-items-center justify-content-center boxBorder pt-lg-0 pb-4">
                        <span className="pt-2 pb-2">
                            {getText("title.main")}
                        </span>
                        <span className="pb-4 text-center">
                            {getText("title.main2")}
                        </span>
                        <a
                            href={getText("cv")}
                            download
                            className="primaryButton buttonText"
                        >
                            {getText("button")}
                        </a>
                    </div>
                </div>

                <div className="row ps-0 pe-0 ps-sm-5 pe-sm-5">
                    <div className="col-12 regularText ps-0 pe-0 ps-md-5 pe-md-5 pt-2 pt-lg-5">
                        <p>{getText("text.main")}</p>

                        <ReadMore>{getText("text.main2")}</ReadMore>

                        <p>{getText("text.cta")}</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export { About };