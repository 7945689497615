import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import { Header } from "./components/Header/Header";
import { Main } from "./components/Main/Main";
import { Footer } from "./components/Footer/Footer";
import { LaMadriguera } from "./components/LaMadriguera/LaMadriguera";
import { useLocation } from 'react-router-dom';
import Provider from './context/Context';
import ReactGA from 'react-ga4';

const App = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname,
          });
    }, [location]);

    return (
        <>
            <Provider>
                <Header />
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/lamadriguera" element={<LaMadriguera />} />
                </Routes>
                <Footer />
            </Provider>
        </>
    );
};

export { App };