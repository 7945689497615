import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import LanguageSwitcher from "../SwitchLanguage/switchLanguage";
import { Context } from "../../context/Context";
import { useContext } from "react";
import "./navBar.css";

function NavBar() {
    const { getText } = useContext(Context);
    const [navbar, setNavbar] = useState(false);

    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };

    window.addEventListener("scroll", changeBackground);

    return (
        <>
            <Navbar
                bg="light"
                expand="lg"
                className={`fixed-top navbar ${navbar ? "active" : " "}`}
            >
                <Container fluid>
                    <Navbar.Brand href="/">
                        <img
                            src="./assets/logo.svg"
                            className="d-inline-block align-center logo"
                            alt="SúperGise logo"
                        />
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />

                    <Navbar.Collapse
                        id="basic-navbar-nav"
                        className="justify-content-end"
                    >
                        <Nav className="ml-auto">
                            <Nav.Link className="navBarText" href="/#about">
                                {getText("navbar")}
                            </Nav.Link>
                            <Nav.Link className="navBarText" href="/#works">
                                {getText("navbar2")}
                            </Nav.Link>
                            <Nav.Link className="navBarText" href="/#contact">
                                {getText("navbar3")}
                            </Nav.Link>
                        </Nav>

                        <Nav className="ml-auto navBarText">
                            <LanguageSwitcher></LanguageSwitcher>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export { NavBar };